.msc-cart__btn-guestcheckout:hover,
.msc-cart__btn-guestcheckout:focus {
    background-color: black !important;
    color: white;
    text-decoration: none;
}

a.msc-cart__btn-checkout:hover {
    color: white;
    text-decoration: none;
}

.msc-cart-line__product-variants,
.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn,
.msc-order-summary__items,
.msc-cart-line__other-charges-label,
.msc-cart-line__freight-label,
.msc-cart-line .shipping-value,
.msc-cart-line .other-charge-value,
.msc-cart-line__product-title,
.msc-cart-line__remove-item,
.msc-cart-line__add-to-order-template,
.msc-cart-line__add-to-wishlist,
.msc-btn,
.msc-promo-code__input-box {
    font-family: 'Futura PT Book', monospace;
}

.msc-promo-code__group {
    padding-bottom: 30px;
}

.ms-checkout__guided-card-btn-save {
    background-color: var(--msv-promo-banner-bg);
    border: 1px solid var(--msv-promo-banner-bg);
}
