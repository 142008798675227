$avantgarde: 'ITC Avant Garde Gothic';
$futura: 'Futura PT Book';
$futura-bold: 'Futura Bold';
$bignoodle: 'Big Noodle Titling';
$konexy: 'Konexy';
$tgb-font-path: '../../../freedom/webfonts';

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
    font-family: $avantgarde;
    src: url('#{$tgb-font-path}/ITCAvantGardeStdBold.woff') format('woff');
    // url("#{$tgb-font-path}/ITCAvantGardeStdBold.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $futura;
    src: url('#{$tgb-font-path}/futura-pt-book.otf') format('otf'), url('#{$tgb-font-path}/futura-pt-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: $futura-bold;
    src: url('#{$tgb-font-path}/futura-bold.ttf') format('ttf'), url('#{$tgb-font-path}/futura-bold.otf') format('otf'),
        url('#{$tgb-font-path}/futura-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $bignoodle;
    src: url('#{$tgb-font-path}/big_noodle_titling.ttf') format('ttf'), url('#{$tgb-font-path}/big_noodle_titling.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $konexy;
    src: url('#{$tgb-font-path}/KonexyPersonalUse-rg3wK.ttf') format('ttf'),
        url('#{$tgb-font-path}/KonexyPersonalUse-rg3wK.otf') format('otf'),
        url('#{$tgb-font-path}/KonexyPersonalUse-rg3wK.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html,
body {
    font-family: 'Futura PT Book', Arial, Tahoma, sans-serif;
    // font-family: 'Roboto Mono', monospace;
    font-weight: 400;
}
h1,
h2,
h3,
h4,
h5 {
    // font-family: $konexy, 'Futura PT Book', Helvetica, Arial, sans-serif !important;
    font-family: 'Futura PT Book', Helvetica, Arial, sans-serif !important;
    line-height: 1;
    margin-bottom: 8px;
    font-weight: 400;
}

strong {
    font-weight: 700;
}
