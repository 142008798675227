// Styles from your friend Nick

.Size {
    .msc-btn {
        position: relative;
        left: -9999em;
    }
    .msc-btn::before {
        position: relative;
        right: -9999em;
        content: "Volume";
    }
    .msc-btn::after {
        position: relative;
        right: -9999em;
    }
}