.footer-container {
    padding-top: 30px;
}

.footer-container > div.row > div.col-md-3 > ul {
    list-style: none;
}

.ms-footer__item.footer-logo {
    padding: 20px;
}

:root {
    --msv-footer-bg: #161616;
    --msv-footer-heading-font-color: #a7a9aa;
    --msv-footer-link-font-color: #a7a9aa;
}
.ms-footer__list-item {
    color: var(--msv-footer-heading-font-color);
}

.ms-text-block.footer-content {
    color: var(--msv-footer-heading-font-color);
}

.ms-text-block.footer-content a {
    color: var(--msv-footer-heading-font-color);
}

.footer-content ul {
    list-style: none;
    padding-left: 0;
}

.footer-content ul a {
    text-decoration: none;
}

.footer-header > div.row > div.col-12 > div.ms-content-block {
    min-height: 0px;
}

.footer-header > div.row > div.col-12 > div.ms-content-block > div.ms-content-block__image {
    display: flex;
    justify-content: center;
}

.footer-header > div.row > div.col-12 > div.ms-content-block > div.ms-content-block__image > picture > img {
    height: 20px;
    width: auto;
}

.footer-footer > div.row > div.col-12 > div.ms-text-block > div > p {
    color: var(--msv-footer-heading-font-color);
}

.footer-footer > div.row > div.col-12 > div.ms-text-block {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

ul.ms-footer__list {
    ul {
        list-style: none !important;
        padding: 0 !important;
        list-style-type: none;
    }
    li {
        list-style-position: inherit;
    }
}

.footer-email-signup {
    .ms-text-block {
        margin-top: 0px;
    }
}

.main-footer-col-4 {
}

.footer-container {
    .col-md-3:last-of-type {
        .ms-footer__list-item:last-of-type {
            .footer-content {
                margin-top: 10px;
            }
        }
    }
}
