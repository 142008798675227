.msc-cart-lines-group-wraper__bopis-heading {
    display: none !important;
}

.msc-cart-lines-item {
    margin: 0 !important;
    padding-top: 20px;
}
.cart-container {
    @media (min-width: 768px) {
        min-height: 500px;
    }
    a.msc-cart__btn-backtoshopping {
        width: 250px;
    }
}
