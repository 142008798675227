.ms-content-block.ffb-team-section-part > div.ms-content-block__image > picture > img,
.ms-content-block.ffb-amb-section-part > div.ms-content-block__image > picture > img {
    width: 100%;
}

.ms-content-block.ffb-team-section-part,
.ms-content-block.ffb-amb-section-part {
    padding: 0px 10px;
}

.ms-content-block.ffb-team-section-part > div.ms-content-block__details,
.ms-content-block.ffb-amb-section-part > div.ms-content-block__details {
    text-align: center;
    padding-top: 10px;
}

.ffb-team-section {
    padding-bottom: 30px;
    padding-top: 10px;
}

.ffb-amb-section {
    padding-top: 10px;
}

@media only screen and (max-width: $msv-breakpoint-m) {
    .ms-content-block.ffb-team-section-part,
    .ms-content-block.ffb-amb-section-part {
        padding: 10px;
    }
    .ffb-team-page-images > div.row > div.col-md-6 > div {
        padding: 15px 15px !important;
    }
}

.ffb-team-page-text > div.ms-content-block__details > div.ms-content-block__text > div > p {
    padding: 10px 0;
}

.ffb-team-page-right,
.ffb-team-page-left {
    padding: 0 15px;
}

.ffb-team-page-image-2 > div.ms-content-block__details > h6 {
    text-align: center;
}

.ffb-team-page-images > div.row > div.col-md-6 > div {
    padding: 0 15px;
}

.ffb-team-page-images {
    padding: 30px 0;
    border-top: 1px black solid;
    border-bottom: 1px black solid;
}

.ffb-team-page-instagram > div.row > div.col-12 > div.ms-content-block > div.ms-content-block__details > h2.ms-content-block__title {
    display: none;
}

.ffb-amb-section {
    .row {
        > div {
            width: 20%;
            max-width: 20%;
            @media (max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

.team-page-title {
    background: black;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ms-text-block {
        margin: 0;
        h1 {
            color: white;
        }
    }
}
