.ms-product-search-result__item a .msc-product__title {
    font-family: 'Futura Bold', Arial, Tahoma, sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.ms-product-search-result__item .msc-product__image img {
    height: auto !important;
}

.ms-search-result-container__Sort-by-category {
    padding-bottom: 20px;
}

label.msc-l-dropdown__label {
    margin-bottom: 0px;
}

.ms-refine-submenu.list-group.Rating {
    display: none;
}

//Hide Breadcrumbs
.ms-search-result-container__category-nav-section {
    padding-top: 10px;
    nav.ms-search-result-container__category-hierarchy {
        color: transparent;
        pointer-events: none;
        cursor: default;
        a {
            color: transparent;
            pointer-events: none;
            cursor: default;
        }
    }
}

//Refiner Ordering

.foilboard-cat-page {
    .ms-search-result-container__refiner-section {
        .ms-search-result-container__refine-menu {
            display: flex;
            flex-direction: column;
            .Category {
                order: 0;
            }
            .Activity {
                order: 2;
                div {
                    ul.Activity {
                        display: flex;
                        flex-direction: column;
                        #Activity_0 {
                            order: 0;
                        }
                        #Activity_3 {
                            order: 1;
                        }
                        #Activity_2 {
                            order: 2;
                        }
                        #Activity_1 {
                            order: 3;
                        }
                    }
                }
            }
            .Size {
                order: 3;
                button::before {
                    content: 'Volume';
                    color: black;
                }
                button::after {
                    color: black;
                }
                button {
                    color: white;
                }
                div {
                    ul.Size {
                        display: flex;
                        flex-direction: column;
                        #Size_6 {
                            order: 0;
                        }
                        #Size_4 {
                            order: 1;
                        }
                        #Size_7 {
                            order: 2;
                        }
                        #Size_3 {
                            order: 3;
                        }
                        #Size_0 {
                            order: 4;
                        }
                        #Size_1 {
                            order: 5;
                        }
                        #Size_2 {
                            order: 6;
                        }
                        #Size_5 {
                            order: 7;
                        }
                        #Size_8 {
                            order: 8;
                        }
                    }
                }
            }
        }
    }
}

.ultimate-wing-foil-cat .ms-search-result-container__refiner-section {
    display: none;
}

.ultimate-wing-foil-cat .ms-search-result-container__title {
    display: none;
}
