.ms-checkout__body {
    font-family: 'Futura PT Book', monospace;
}

.ms-checkout__guided-card-btn-save,
.msc-cart__btn-checkout,
.msc-cart__btn-backtoshopping,
.ms-cart-icon__btn-checkout,
.ms-checkout__btn-place-order,
.ms-checkout__btn-keep-shopping {
    background-color: #e72129 !important;
    border: 1px solid #e72129;
    color: white;
}

.ms-checkout__guided-card-btn-save:hover,
.ms-checkout__guided-card-btn-save:focus,
.ms-checkout__guided-card-btn-save:active,
.msc-cart__btn-checkout:hover,
.msc-cart__btn-checkout:focus,
.msc-cart__btn-checkout:active,
.msc-cart__btn-backtoshopping:hover,
.msc-cart__btn-backtoshopping:focus,
.msc-cart__btn-backtoshopping:active,
.ms-cart-icon__btn-checkout:hover,
.ms-cart-icon__btn-checkout:focus,
.ms-cart-icon__btn-checkout:active,
.ms-checkout__btn-place-order:hover,
.ms-checkout__btn-place-order:focus,
.ms-checkout__btn-place-order:active,
.ms-checkout__btn-keep-shopping:hover,
.ms-checkout__btn-keep-shopping:active,
.ms-checkout__btn-keep-shopping:focus {
    background-color: #000 !important;
    border: 1px solid #000;
    color: white;
    text-decoration: none;
}

.msc-cart-line__quantity__select-menu {
    margin-right: 20px;
    height: 25px;
}

.ms-checkout__guided-card-btn-cancel:hover {
    background-color: #cccccc !important;
    border: 1px solid #cccccc;
    color: black;
}

.ms-checkout-terms-and-conditions__container {
    a {
        padding-right: 3px;
        font-size: 15px;
    }
}

.msc-address-form__input,
.msc-address-form__dropdown,
.ms-checkout-guest-profile__input-text {
    border: black solid 1px;
}

.ms-checkout-shipping-address .msc-address-detail {
    font-family: 'Futura PT Book', monospace;
}

.msc-waiting-circular {
    border-top-color: #e72129;
}

.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-gotocart:hover,
.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-gotocart:focus {
    background-color: #e4e4e4;
    border: 1px solid black;
    color: black;
    text-decoration: none;
}

.ms-checkout__main-control,
.ms-checkout__side-control-first {
    flex-direction: column;
    .fixThis {
        padding: 10px 0;
    }
    .ms-checkout__btn-place-order {
        margin: 10px 0 !important;
    }
}

// this is an edit for Rhett and Kathy, Nick did this, i will take this away once i'm done.

.msc-order-summary__label::before {
    content: 'Estimated ';
}
