.story-our-founder {
    background-color: #242424;
    .story-our-founder-content {
        .story-our-founder-heading {
            color: #d7d7d7;
        }
        .story-our-founder-desc {
            color: #d7d7d7;
            font-size: 20px;
            h3,
            p {
                text-align: center;
            }
        }
        .eapps-widget {
            padding-top: 30px;
        }
    }
}

.parallax-container-1,
.parallax-container-2 {
    background-color: #242424;
}

.parallax-story-1 {
    background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA7VG8?pubver=1');

    min-height: 500px;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.story-our-story {
    background-color: #242424;
    .story-our-story-content {
        h3,
        p {
            text-align: center;
            color: #d7d7d7;
        }
        p {
            padding: 10px 0;
        }
    }
}

.lightbox-container-2eec9a67-d323-42d1-9247-3ea5ae664f26 {
    .fslightbox-caption-wrapper {
        display: none;
    }
}

.parallax-story-2 {
    background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA7Yhv?pubver=1');
    min-height: 500px;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.story-our-mission {
    background-color: #242424;
    .story-our-mission-content {
        p {
            padding: 10px 0;
        }
        h3,
        p {
            text-align: center;
            color: #d7d7d7;
        }
        padding-bottom: 30px;
    }
}
