.ms-accordion-header-section__heading {
    font-size: 42px;
    margin: 50px 0;
}

.ffb-faq-item > div > button > span {
    font-family: 'ITC Avant Garde Gothic', 'Futura PT Book', Helvetica, Arial, sans-serif !important;
    line-height: 1;
    text-align: left;
}

.ffb-faq-accordion {
    margin-bottom: 30px;
}
