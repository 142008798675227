.customs-board-info {
    div.ms-content-block__image {
        picture {
            display: flex;
            img {
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }
    }
    div.ms-content-block__details {
        h2.ms-content-block__title {
            text-align: center;
            padding-bottom: 30px;
            text-transform: uppercase;
        }
    }
}

.customs-embed {
    padding-bottom: 30px;
}

.customs-main-logo {
    min-height: 0px;
    div.ms-content-block__image {
        picture {
            display: flex;
            img {
                max-width: 768px;
                padding-top: 30px;
                padding-bottom: 30px;
                justify-content: center;
                display: block;
                margin-left: auto;
                margin-right: auto;
                @media (max-width: 768px) {
                    max-width: 90vw;
                }
            }
        }
    }
}

.customs-main-image {
    div.ms-content-block__image {
        picture {
            display: flex;
            img {
                max-width: 768px;
                justify-content: center;
                display: block;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 50px;
                @media (max-width: 768px) {
                    max-width: 90vw;
                }
            }
        }
    }
}
