.fb-profile-container {
    .fb-profile-welcome {
    }

    .fb-profile-user {
        .ms-account-loyalty-tile__heading,
        .ms-account-profile__section-preferences > .ms-account-profile__section-heading {
            font-size: 24px;
            padding-bottom: 10px;
        }
        .ms-account-loyalty-tile__heading:before {
            display: none;
        }
    }

    .fb-profile-orders {
    }

    .fb-profile-wishlist {
    }

    .fb-profile-address {
    }
}
