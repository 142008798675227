.closeout-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
    > .row {
        justify-content: center;
    }
    > .row > div.col-12 {
        max-width: 20%;
        @media (max-width: 768px) {
            max-width: 100%;
        }
        .closeout-children {
            padding-bottom: 15px;
            h2 {
                text-align: center;
            }
            p {
                text-align: center;
                span.strikeout {
                    text-decoration: line-through;
                }
                span.save {
                    color: red;
                    font-weight: bold;
                }
            }
        }
    }
}

.closeout-footer {
    padding-bottom: 50px;
}
