.warranty {
    ul.warranty-list {
        list-style-type: disc;
        @media (min-width: 768px) {
            padding: 0 0 0 40px;
        }
    }
    li.warranty-list-item {
        list-style-position: inside;
        padding: 5px 0;
    }
}
