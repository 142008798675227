.ffb-home-slider-desktop {
    @media only screen and (max-width: 992px) {
        display: none;
    }
    @media only screen and (min-width: 992px) {
        display: block;
    }
    .ffb-home-slider-slide-1 > div > picture > img,
    .ffb-home-slider-slide-2 > div > picture > img,
    .ffb-home-slider-slide-3 > div > picture > img,
    .ffb-home-slider-slide-4 > div > picture > img {
        width: 100%;
    }
    .msc-carousel__indicators {
        display: none;
    }
    a.ms-content-block__link {
        border: none;
    }
}

.ffb-home-slider-mobile {
    @media only screen and (max-width: 992px) {
        display: block;
    }
    @media only screen and (min-width: 992px) {
        display: none;
    }
    .ffb-home-slider-slide-1 > div > picture > img,
    .ffb-home-slider-slide-2 > div > picture > img,
    .ffb-home-slider-slide-3 > div > picture > img,
    .ffb-home-slider-slide-4 > div > picture > img {
        width: 100%;
    }
    .msc-carousel__indicators {
        display: none;
    }
}
