/* Delete start here */
.ffb-home-slider-content > div.ms-content-block__image > picture > img {
    width: 100%;
}

div.ffb-home-slider-mobile > div.msc-carousel > ol.msc-carousel__indicators {
    display: none;
}

@media screen and (min-width: 1550px) {
    .eas-slider-component.jsx-1560869914 .eas-slider-slider {
        height: 100vh;
    }
    .ffb-home-slider-mobile {
        display: none;
    }
    .ffb-home-slider {
        display: block;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1550px) {
    .eas-slider-component.jsx-1560869914 .eas-slider-slider {
        height: 85vh;
    }
    .ffb-home-slider-mobile {
        display: none;
    }
    .ffb-home-slider {
        display: block;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
    .eas-slider-component.jsx-1560869914 .eas-slider-slider {
        height: 70vh;
    }
    .ffb-home-slider-mobile {
        display: none;
    }
    .ffb-home-slider {
        display: block;
    }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
    .eas-slider-component.jsx-1560869914 .eas-slider-slider {
        height: 50vh;
    }
    .ffb-home-slider-mobile {
        display: none;
    }
    .ffb-home-slider {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .ffb-home-slider-mobile {
        display: block;
    }
    .ffb-home-slider {
        display: none;
    }
}

/* Delete end here */

.msc-carousel__control__prev {
    margin-left: 20px;
}

.msc-carousel__control__next {
    margin-right: 20px;
}

.ffb-home-board-container-section > div > div > div.ms-content-block > div.ms-content-block__details > div.ms-content-block__text > p {
    font-size: 19px;
    line-height: 2;
}

.ffb-home-board-container-section
    > div
    > div
    > div.ms-content-block
    > div.ms-content-block__details
    > div.ms-content-block__cta
    > a.msc-cta__primary {
    font-weight: bold;
    font-size: 22px;
    color: white;
    background-color: black;
    padding: 15px;
    margin-top: 15px;
    text-align: center;
}

.ffb-home-board-container-section > div.row > div {
    align-items: center;
    display: flex;
    justify-content: center;
}

.ffb-home-board-container-section {
    padding: 50px;
    border-top: 1px black solid;
    border-bottom: 1px black solid;
}

.reversed > div.row {
    flex-direction: row-reverse;
}

.ffb-homepage-text {
    padding: 50px 0;
}

.ms-content-block.dedicated-to-flight-img {
    min-height: 15px;
}

.home-page-sub-slider-boxes > div.ms-content-block__details > div.ms-content-block__text > p {
    // color: $brandSecondaryColor;
}

.home-page-sub-slider-boxes > div.ms-content-block__details > h2.ms-content-block__title {
    font-family: 'Futura Bold', Arial, Tahoma, sans-serif !important;
}

@media only screen and (min-width: 1200px) {
    .home-page-sub-slider-boxes > div.ms-content-block__details > h2.ms-content-block__title {
        font-size: 28px;
    }
}

@media only screen and (min-width: 850px) and (max-width: 1200px) {
    .home-page-sub-slider-boxes > div.ms-content-block__details > h2.ms-content-block__title {
        font-size: 18px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
    .home-page-sub-slider-boxes > div.ms-content-block__details > h2.ms-content-block__title {
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .home-page-sub-slider-boxes > div.ms-content-block__details > h2.ms-content-block__title {
        font-size: 32px;
    }
}

.ms-content-block.home-page-sub-slider-boxes {
    padding: 20px 10px;
}

.home-page-hero-side-image {
    padding: 40px 0;
}

.home-page-hero-side-image > div > div.col-lg-4 > div.ms-content-block > div.ms-content-block__details > h2 {
    text-align: center;
}

.home-page-hero-side-image > div > div.col-lg-8 > div > div.ms-content-block__image > picture > img {
    width: 100%;
}

.home-page-hero-side-image-image > div.ms-content-block__image > picture > img {
    width: 100%;
}

.home-page-hero-side-image-text > div.ms-content-block__details {
    padding: 0 20px;
    /* REVERT
    text-align:center; */
    text-align: left;
    @media (max-width: 768px) {
        text-align: center;
    }
}

.home-page-hero-side-image-text {
    display: flex;
    flex-direction: column;
    /*  REVERT
    flex-direction: row;
    align-items: center; */
    height: 100%;
    align-items: start;
    max-width: 500px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
    .ms-content-block__image {
        align-self: auto;
        padding-bottom: 30px;
        max-width: 200px;
    }
    .ms-content-block__details {
        align-self: auto;
        width: 100%;
        .ms-content-block__text {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
        }
    }
    .ms-content-block__cta {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: start;
        padding-top: 20px;
        padding-bottom: 20px;
        @media (max-width: 768px) {
            align-items: center;
        }
        a {
            padding: 10px 30px;
            border: solid black 2px;
            width: 200px;
            display: flex;
            justify-content: center;
        }
        a:hover {
            text-decoration: none;
            color: white;
            background-color: black;
        }
    }
}

.home-page-hero-side-image {
    padding-top: 5px;
    padding-bottom: 5px;
}

.home-page-hero-side-image {
    .row {
        background-color: #f3f3f3;
    }
}

.home-page-hero-side-image.reverse {
    .row {
        flex-direction: row-reverse;
        .home-page-hero-side-image-text {
            flex-direction: column;
            align-items: end;
            max-width: 500px;
            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }
            > div.ms-content-block__details {
                text-align: right;
                @media (max-width: 768px) {
                    text-align: center;
                }
            }
            .ms-content-block__cta {
                align-items: end;
                @media (max-width: 768px) {
                    align-items: center;
                }
            }
        }
    }
}

.home-page-hero-side-image-image {
    padding-top: 30px;
}

.home-page-hero-side-image > div.row:last-child {
    align-items: center;
    /* REVERT */
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    div.col:nth-child(1) {
        max-width: 300px;
        @media (max-width: 768px) {
            max-width: 500px;
        }
    }
    div.col:nth-child(2) {
        max-width: 400px;
    }
}

.dedicated-to-flight {
    min-height: 0px;
}

.dedicated-to-flight > div.ms-content-block__details > div.ms-content-block__text > div.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 40px;
}
.dedicated-to-flight > div.ms-content-block__details > div.ms-content-block__text > div.wrapper > div {
    // font-family: 'Konexy', 'Futura PT Book', Helvetica, Arial, sans-serif !important;
    font-family: 'Futura PT Book', Helvetica, Arial, sans-serif !important;
    font-size: 18px;
}

/* .home-page-hero-side-image-text > div.ms-content-block__image {
    padding: 0 10px;
}

.home-page-hero-side-image-text > div.ms-content-block__details {
    padding-top: 30px;
} 

.home-page-hero-side-image-text > div.ms-content-block__details > div.ms-content-block__text ul {
    text-align: left;
} */

.homepage-instagram {
    padding-bottom: 30px;
    padding-top: 30px;
}

// Homepage Video

.homepage-fullwidth-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.homepage-fullwidth-video iframe,
.homepage-fullwidth-video object,
.homepage-fullwidth-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
