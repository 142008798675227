// Styles for the new custom navbar

// image path
$mega: '../../../freedom/img/mega-menu';
$screen-md: 992px;
$msv-icon-font-family: 'Font Awesome 5 Free';

.navBar {
    //span,
    //.important {
    //font-family: $bebas;
    //}
    .submenu {
        position: absolute;
        //top: 100px;
    }
}

.no-relative {
    .ob-low-header {
        position: relative;
    }
    .obrien-header-2021 {
        position: static;
    }
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        position: static;
    }
}

.ms-header {
    .navBar {
        a:link,
        a:active,
        a:visited {
            color: #fff;
        }
        a:hover {
            color: #000;

            cursor: pointer;
        }
    }
}

.navBar {
    flex: 1;
    display: none;
    min-height: 87px;
    display: none;
    @media screen and (min-width: $screen-md) {
        display: flex;
        //font-size: 1.2em;
    }
    a {
        padding: 0 14px;
        text-decoration: none;
        display: block;
        transition: color 0.3s ease;

        @media screen and (min-width: $screen-md) {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .navbar-item {
        font-family: 'Futura Bold', Arial, Tahoma, sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        background: rgba($color: #fff, $alpha: 0);
        flex-grow: 1;
        transition: background 0.3s ease;
        span {
            position: relative;
            z-index: 10;
        }
        .submenu {
            font-size: 0.95em;
            position: absolute;
            top: 87px;
            left: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.05);
            z-index: 1000;
            ul {
                padding: 10px;
            }
            li {
                //width: 300px;
                //font-size: 1.2em;
                margin-bottom: 7px;
                line-height: 1.1;
                a {
                    display: block;
                    padding: 2px 4px;
                    transition: all 0.3s ease;
                    text-transform: capitalize;
                    // font-family: 'Futura PT Book', Arial, Tahoma, sans-serif;
                    font-family: 'Futura PT Book', monospace;
                    font-size: 16px;
                    text-transform: uppercase;
                }
                a:link,
                a:active,
                a:visited {
                    color: #000;
                }
                a:hover {
                    //color: $ob-blue;
                    //background: rgba($color: #000, $alpha: 0.8);
                    cursor: pointer;
                }
            }
            li:first-child {
                //font-family: $bebas;
                position: relative;
                padding-bottom: 4px;
            }
            li:first-child::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                //background: $ob-ltltgray;
                bottom: 0;
                left: 0;
            }
            //li:not(:first-child) {
            //font-size: 0.8em;
            //a:link,
            //a:active,
            //a:visited {
            //background: rgba($color: $ob-ltltgray, $alpha: 0);
            //}
            //a:hover {
            //    color: #000;
            //    background: rgba($color: $ob-ltltgray, $alpha: 1);
            //}
            //}
        }
    }
    .navbar-item:hover {
        background: rgba($color: #fff, $alpha: 1);
        a {
            color: #000;
        }
        .submenu {
            opacity: 1;
            visibility: visible;
        }
    }

    .navbar-item:nth-child(1) {
        position: static;
        .submenu {
            width: 100%;

            .inside-xxl {
                display: flex;
                flex-wrap: wrap;
                padding: 20px;
                min-height: 421px;
                h4 {
                    color: #000;
                    // font-family: $futura !important;
                    font-family: 'Futura Bold', Arial, Tahoma, sans-serif !important;
                    font-size: 16px;
                    border-bottom: 1px solid #000;
                    font-weight: bold;
                    padding-bottom: 5px;
                }

                @media screen and (min-width: $screen-md) {
                    padding: 20px 20px 20px 220px;
                }
                @media screen and (min-width: $screen-lg) {
                    padding: 20px 20px 20px 378px;
                }

                > ul {
                    flex: 0 0 20%;
                }
                ul {
                    li.image-holder {
                        background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/jfcsjcbsqg/imageFileData/MAfjxS?ver=7039');
                        background-repeat: no-repeat;
                        background-position: center;
                        @media screen and (min-width: $screen-md) {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 220px;
                            display: block;
                            min-height: 421px;
                        }
                        @media screen and (min-width: $screen-lg) {
                            width: 378px;
                        }
                    }
                }
                //Foilboards
                ul:nth-child(1) {
                    li.image-holder {
                        background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAddsQ?ver=6de6');
                        background-size: contain;
                    }
                }
                ul:hover {
                    li.image-holder {
                        z-index: 10;
                    }
                }
                //Foils
                ul:nth-child(2) {
                    li.image-holder {
                        background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAghmF?ver=2714');
                        background-size: contain;
                    }
                }
                //Wind Wings
                ul:nth-child(3) {
                    li.image-holder {
                        background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/jfcsjcbsqg/imageFileData/MAfjxV?ver=b2ad');
                        background-size: contain;
                    }
                }
                //Essentials
                ul:nth-child(4) {
                    li.image-holder {
                        background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/jfcsjcbsqg/imageFileData/MAfjxP?ver=896c');
                        background-size: contain;
                    }
                }
                //More Links
                ul:nth-child(5) {
                    li.image-holder {
                        background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/jfcsjcbsqg/imageFileData/MAfelJ?ver=58da');
                        background-size: contain;
                    }
                }
                ul:nth-child(6) {
                    li.image-holder {
                        background-image: url('#{$mega}/New2022-Tubing-Mega.jpg');
                    }
                }
                ul:nth-child(7) {
                    li.image-holder {
                        background-image: url('#{$mega}/2022-Floating-Mega.jpg');
                    }
                }
                ul:nth-child(8) {
                    li.image-holder {
                        background-image: url('#{$mega}/New2022-TowRopes-Mega.jpg');
                    }
                }
                ul:nth-child(9) {
                    li.image-holder {
                        background-image: url('#{$mega}/2022-LifeJackets-Mega.jpg');
                    }
                }
                ul:nth-child(10) {
                    li.image-holder {
                        background-image: url('#{$mega}/2022-MoreGear-Mega.jpg');
                    }
                }
            }
        }
    }

    .navbar-item {
        position: relative;
        .submenu {
            min-width: 240px;
        }
    }
    //.navbar-item:nth-child(2),
    //.navbar-item:nth-child(5) {
    //    position: relative;
    //    .submenu {
    //        min-width: 240px;
    //    }
    //}
    /*
    .navbar-item:nth-child(5) {
        .submenu {
            right: -63px;
            left: auto;
            .inside-xxl {
                position: relative;
            }

            li {
                //font-size: 0.8em;
                //font-family: $roboto;
                padding-bottom: 0;
                //a:link,
                //a:active,
                //a:visited {
                //    background: rgba($color: $ob-ltltgray, $alpha: 0);
                //}
                //a:hover {
                //    color: #000;
                //    background: rgba($color: $ob-ltltgray, $alpha: 1);
                //}
            }

            li::after {
                height: 0;
                width: 0;
                background: none;
            }
        }
    }
    
    .navbar-item:nth-child(1),
    .navbar-item:nth-child(2),
    .navbar-item:nth-child(5) {
        padding-right: 6px;
        a {
            position: relative;
            padding-right: 18px;
        }
        > a::after {
            position: absolute;
            font-family: 'Font Awesome 5 Free';
            -moz-osx-font-smoothing: grayscale;
            font-style: normal;
            font-variant: normal;
            font-weight: 900;
            text-rendering: auto;
            line-height: 1;
            color: var(--msv-nav-carot-color);
            content: '';
            font-size: var(--msv-nav-font-size);
            margin-left: 8px;
            line-height: 40px;
            transform: rotate(-90deg);
            right: 0;
            transition: transform 0.4s ease;
        }
    }
    .navbar-item:nth-child(1):hover,
    .navbar-item:nth-child(2):hover,
    .navbar-item:nth-child(5):hover {
        > a::after {
            transform: rotate(0deg);
        }
    }
    */
    .navbar-item {
        padding-right: 30px;
        a {
            position: relative;
            padding-right: 18px;
        }
        > a::after {
            position: absolute;
            font-family: 'Font Awesome 5 Free';
            -moz-osx-font-smoothing: grayscale;
            font-style: normal;
            font-variant: normal;
            font-weight: 900;
            text-rendering: auto;
            line-height: 1;
            color: var(--msv-nav-carot-color);
            content: '';
            font-size: var(--msv-nav-font-size);
            margin-left: 8px;
            line-height: 40px;
            transform: rotate(-90deg);
            right: 0;
            transition: all 0.4s ease;
        }
    }
    .navbar-item:hover {
        > a::after {
            transform: rotate(0deg);
            color: #000;
        }
    }
}

.ob-low-header {
    .navBar-mobile {
        display: none;
    }
}
.desktop-nav {
    display: none !important;
    @media screen and (min-width: $screen-md) {
        display: flex !important;
    }
}
.navBar-mobile {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    background: #fff;
    h4 {
        display: none;
    }
    @media screen and (min-width: $screen-md) {
        display: none;
    }

    .accordion-inner,
    .navbar-item a,
    .obrien-nav__item a {
        background: none;
        border: none;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 5px 20px;
        text-align: left;
    }
    .accordion-inner {
        position: relative;
        //border-bottom: 1px solid $ob-ltltgray;
        //font-family: $bebas;
    }
    .accordion-inner::after {
        font-family: $msv-icon-font-family;
        content: $msv-plus;
        position: absolute;
        right: 25px;
        box-sizing: border-box;
        font-size: 0.6em;
        font-weight: 900;
        top: 50%;
        transform: translateY(-50%) rotate(0);
        transition: transform 0.3s ease;
    }
    .accordion-inner.active::after {
        transform: translateY(-50%) rotate(45deg);
    }
    //.navbar-item > a {
    //border-bottom: 1px solid $ob-ltltgray;
    //font-family: $bebas;
    //}
    .obrien-nav__item a {
        font-size: 0.8em;
    }
}

/*
.fade {
    @include transition($transition-fade);

    &:not(.show) {
        opacity: 0;
    }
}

.collapse {
    &:not(.show) {
        display: block !important;
    }
}
*/
.collapsing {
    position: relative;
    height: 0;
    min-height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

/* REMOVE TO SHOW FOILS AGAIN
.navBar {
    .navbar-item {
        .submenu {
            .inside-xxl {
                .product-nav-container__list:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}
//END REMOVE */
