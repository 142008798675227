// Settings index file
@import 'icons';
@import 'grid';
@import 'custom-fonts';
@import 'ada-compliance';

.ob-header-image > picture > img {
    width: 100%;
}
.center-text {
    text-align: center;
}

.divider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.obrien-vimeo {
    height: 600px;
    @media only screen and (max-width: 768px) {
        height: 50vw;
    }
}

// .msc-btn {
//     background-color: #e72129 ;
//     border: 1px solid #e72129 ;
//     color: white;
//     padding: 12px 20px;
// }

.msc-btn:focus {
    border: none;
    outline: none;
}

.ms-search__autoSuggest {
    img {
        max-height: 45px;
    }
}

.dont-show {
    display: none;
}

.transparent-text {
    color: transparent !important;
}

.bottom-border {
    border-bottom: 1px black solid;
    margin-bottom: 10px;
}
