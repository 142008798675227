#shipping_addressstate {
    option[value='AA'],
    option[value='ACT'],
    option[value='AE'],
    option[value='AP'],
    option[value='AS'],
    option[value='BC'],
    option[value='California'],
    option[value='FM'],
    option[value='MH'],
    option[value='AK'],
    option[value='GU'],
    option[value='ON'],
    option[value='QC'],
    option[value='VI'],
    option[value='G8T 2A5'],
    option[value='YT'],
    option[value='PW'],
    option[value='MP'] {
        display: none;
    }
}

#shipping_addressthreeletterisoregionname {
    option {
        display: none;
    }
    option[value='CAN'],
    option[value='USA'] {
        display: block !important;
    }
}
