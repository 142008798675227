.retailers {
    .inside-xl {
        max-width: 1440px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        margin: 0 auto;
    }
    .sixty {
        box-sizing: border-box;
        flex: 0 0 60%;
        /* .clicky-close {
            display: none;
        } */
        // Clicky Button Locator

        div.text-center,
        div.retailer-section-button {
            //Change to proper class once deployed
            padding-bottom: 15px;
        }

        .clicky.active {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            padding: 60px;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.8);
            z-index: 10000;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .button-book {
            background-color: #e72129 !important;
            text-transform: uppercase;
            border-color: #e72129 !important;
            color: #fff !important;
            display: none;
            @media screen and (min-width: 600px) {
                display: inline-block;
            }
        }

        .clicky.notactive {
            .clicky-close {
                display: none;
            }
        }

        .clicky.active {
            .clicky-container {
                position: relative;
                padding-top: 52px;
            }
        }

        .clicky-close {
            position: absolute;
            right: 0;
            margin-top: -52px;
            background: #fff;
            height: 48px;
            width: 100%;
            box-sizing: border-box;
            padding: 5px;
            border-radius: 4px;
            cursor: pointer;
            text-align: center;
            > span {
                padding-top: 10px;
                display: inline-block;
            }
            button.close {
                margin-top: 7px;
                margin-left: 10px;
            }
        }
    }

    .forty {
        box-sizing: border-box;
        flex: 0 0 40%;
        background: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAgeLn?ver=8ab1) center center
            no-repeat;
        background-size: cover;
    }
    a,
    .lcly-dealer {
        transition: background 0.4s ease;
    }
    padding: 30px 0;
    background: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAd0KQ?ver=62fe) center center
        no-repeat;

    h2 {
        position: relative;
        text-align: center;
        padding-bottom: 10px;
        margin-bottom: 40px;
        margin-top: 0;
    }
    h2::after {
        content: '';
        height: 4px;
        width: 16%;
        margin: 0 42%;
        position: absolute;
        background: #e72129;
        left: 0;
        bottom: 0;
    }
    .upc-contain {
        background: #fff;
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.05);
        padding: 10px;
        margin: 30px 0;
        > div {
            padding: 10px;
        }
        .upc-button {
            text-align: center;
            a {
                font-size: 18px;
                display: inline-block;
                font-weight: 700;
                padding: 10px 15px;
            }
            a:link,
            a:active,
            a:visited {
                background: #f9f9f9;
            }
            a:hover {
                background: #eee;
                text-decoration: none;
            }
        }
        .flex-vertical {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            position: relative;
            padding-top: 42px;
            .over-h3 {
                position: absolute;
                top: 0;
                width: 100%;
                font-size: 18px;
                //line-height: 1.112 !important;
                margin-bottom: 10px;
                h3 {
                    position: relative;
                    display: inline-block;
                    padding-bottom: 10px;
                }
                h3::after {
                    content: '';
                    position: absolute;
                    width: 60%;
                    margin: 0 20%;
                    height: 4px;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}

.locator-iframe {
    iframe {
        @media (max-width: 768px) {
            height: 650px;
        }
        @media (min-width: 768px) {
            height: 550px;
        }
    }
}

#storelocator-search-bar {
    background-color: #fff !important;
}

.locally-wrapper {
    display: none;
}
