.twenty-twenty-two-special-product-page {
    /* .ms-buybox__product-title {
        font-family: 'TriesterSans-Vector', 'IntegralCF-Bold', Arial, Tahoma, sans-serif !important;
        color: red;
        text-transform: uppercase;
    } */

    .eapg-info-title {
        font-size: 16px;
    }

    .wakeboard-accordion-container {
        padding-bottom: 30px;
    }
    .feature-focus-2022 {
        padding: 30px 0;
        .ms-text-block {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .feature-focus-2022-section > div {
            display: flex;
            div.col-md-6:last-of-type {
                display: flex;
                align-items: center;
            }
            .feature-focus-2022-section-image {
                background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA5xot?pubver=1);
                min-height: 400px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .feature-focus-2022-section-text {
                padding: 40px;
                @media only screen and (max-width: 768px) {
                    padding: 20px;
                }

                h1 {
                    font-size: 24px;
                    text-transform: uppercase;
                    padding-bottom: 20px;
                }
            }
        }
        .feature-focus-2022-section-reversed > div {
            display: flex;
            flex-direction: row-reverse;
        }

        .image-session-1 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA9OPF?pubver=1) !important;
        }

        .image-session-2 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA9Mad?pubver=1) !important;
        }

        .image-session-3 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA9Mag?pubver=1) !important;
        }

        .image-halcyon-1 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbA5F?ver=d152) !important;
        }
        .image-halcyon-2 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbHPP?ver=8e52) !important;
        }
        .image-halcyon-3 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbA0F?ver=8da0) !important;
        }
        .image-phoenix-1 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbA5F?ver=d152) !important;
        }
        .image-phoenix-2 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbHPP?ver=8e52) !important;
        }
        .image-phoenix-3 {
            background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbA0F?ver=8da0) !important;
        }
    }

    .twenty-twenty-two-wakesurfer-parallax-session {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MA8LHP?pubver=1);
        min-height: 500px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .twenty-twenty-two-wakesurfer-parallax-halcyon {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbFml?ver=1c36);
        min-height: 500px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .twenty-twenty-two-wakesurfer-parallax-phoenix {
        background-image: url(https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAbA5I?ver=96fd);
        min-height: 500px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .twenty-twenty-two-wakesurfer-image {
        div.obrien-content-block-simple__image {
            display: flex;
            justify-content: center;
        }
    }
}
