.reservation-board-info {
    div.ms-content-block__image {
        picture {
            display: flex;
            img {
                max-width: 768px;
                padding-top: 30px;
                padding-bottom: 30px;
                justify-content: center;
                display: block;
                margin-left: auto;
                margin-right: auto;
                @media (max-width: 768px) {
                    max-width: 80vw;
                }
            }
        }
    }
    div.ms-content-block__details {
        h2.ms-content-block__title {
            text-align: center;
            padding-bottom: 30px;
            text-transform: uppercase;
        }
    }
}

.reservation-embed {
    padding-bottom: 30px;
}
