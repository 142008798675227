.ffb23-qr-pages {
    .logo-container {
        > div.row > div.col-12 {
            display: flex;
            justify-content: center;
        }
        .logo-image {
            max-width: 400px;
            .ms-content-block__details {
                display: none !important;
            }
        }
    }
    .video-container {
        .youtube-video-embed {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;
            iframe,
            .video-container object,
            .video-container embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .youtube-video {
            // placeholder
        }
    }
    .follow-container {
        text-align: center;
        .follow-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            a {
                padding: 10px 30px;
                border: solid black 2px;
                width: 200px;
                display: flex;
                justify-content: center;
                text-decoration: none;
            }
            a:hover {
                text-decoration: none;
                color: white;
                background-color: black;
            }
        }
    }
    .board-info-container {
        .board-info {
            p {
                padding: 15px 0;
            }
            ul > li {
                font-size: 16px;
            }
        }
    }
    .board-usp-container {
        > div.row {
            display: flex;
            flex-direction: row;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            > div.col-12 {
                max-width: 33%;
                @media (max-width: 768px) {
                    max-width: 100%;
                }
            }
        }
        .board-usp-section {
            padding: 0 15px;
            @media (max-width: 768px) {
                padding: 30px 0;
            }
            .board-usp-section-image {
                text-align: center;
            }
            .board-usp-section-text {
                text-align: center;
            }
        }
    }
    .board-table-container {
        .board-table {
            // see /modules/UNL-tables.scss
        }
    }
    .board-construction-container {
        padding-top: 50px;
        padding-bottom: 50px;
        .board-construction-text {
            text-align: center;
        }
        .board-construction-image {
            text-align: center;
        }
        .ffb-board-construction-list {
            column-gap: 50px;
            padding: 0 15px;
            list-style: decimal;
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            font-size: 16px;
            letter-spacing: 0.025em;
            line-height: 1.6;
            font-family: 'Futura PT Book', Arial, Tahoma, sans-serif;
            font-weight: 400;
            @media (max-width: 768px) {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
            }
        }
    }
}
