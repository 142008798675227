;
@import 'bootstrap/scss/bootstrap';

// call to the fabrikam design kit
@import '@msdyn365-commerce-modules/fabrikam-design-kit/src/modules/fabrikam/styles/fabrikam.theme.scss';

// primary variables
$brandPrimaryColor: #ffffff;
$brandSecondaryColor: #121212;
$backgrounDefaultColor: #ffffff;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;

:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgrounDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
    --msv-cart-primary-btn-bg: #e72129 !important;
    --msv-cart-primary-btn-border: #e72129 !important;
    --msv-cart-primary-btn-font-color: #fff !important;
    --msv-accent-brand-alt-color: black !important;
}

body {
    background-color: var(--backgroundDefaultColor);
}

// imports of sass sub folders
@import 'settings/index';
@import 'tools/index';
@import 'modules/index';
@import 'pages/index';

p {
    font-size: 16px;
    letter-spacing: 0.025em;
    line-height: 1.6;
    font-family: $futura, Arial, Tahoma, sans-serif;
    // font-family: 'Roboto Mono', monospace;
    font-weight: 400;
}

iframe {
    border: none;
}

.content-divider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content-divider-hr {
        border-top: 2px solid black;
    }
}
