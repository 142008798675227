.fb-orders-container {
    .fb-orders {
        .ms-order-history__header {
            display: flex;
            justify-content: center;
            h2 {
                padding: 30px 0;
                font-size: 36px;
                text-transform: uppercase;
            }
        }
        .ms-order-history__btn-keep-shopping,
        .ms-order-history__btn-order-details {
            background-color: #e72129;
            border-color: #e72129;
            color: white;
        }
        .ms-order-history__btn-keep-shopping:hover,
        .ms-order-history__btn-keep-shopping:focus,
        .ms-order-history__btn-order-details:hover,
        .ms-order-history__btn-order-details:focus {
            background-color: #b5161c;
            border-color: #b5161c;
            text-decoration: none;
            color: white;
        }
    }
}
