.wing-testimonials-main-container {
    .wing-testimonials-section {
        padding: 15px 0;
        > div.ms-container-background-image {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
                box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
            }
        }
        .wing-testimonials-text {
            text-align: right;
            width: 300px;
            margin-right: 150px;
            color: white;
            font-style: italic;
            h3 {
                font-family: 'Futura PT Book', monospace !important;
            }
            @media (max-width: 768px) {
                text-align: center;
                margin-right: 0px;
            }
        }
    }
}
