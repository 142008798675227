.ms-buybox {
    padding-bottom: 30px;
}

.ms-buybox__quantity,
.ms-buybox__add-to-cart-container {
    margin: 10px 0;
}

.ob-summary {
    flex-direction: column;
    padding-bottom: 0px;
}

.ms-buybox .msc-dropdown__select {
    border-color: black;
}

.ms-media-gallery.vertical .ms-media-gallery__thumbnail-item:not(:last-child) {
    margin-bottom: 50px;
    margin-right: 0;
}

.ms-buybox__product-title {
    font-family: 'Futura Bold', Arial, Tahoma, sans-serif !important;
    font-size: 30px;
    font-weight: bold;
    line-height: 42px;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    .ms-buybox__content {
        padding-bottom: 30px;
    }
}

.msc-add-to-cart:hover,
.msc-add-to-cart:focus {
    background-color: $brandSecondaryColor;
    border-color: $brandSecondaryColor;
}

.msc-add-to-cart {
    background-color: $brandSecondaryColor;
    border: 1px solid $brandSecondaryColor;
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
}

.obrien-buybox > div > ul {
    list-style-type: circle;

    li::marker {
        color: $brandSecondaryColor;
        font-size: 16px;
    }

    li {
        font-size: 15px;
    }
}

.foil-image {
    margin-top: -150px;
}

@media only screen and (max-width: 600px) {
    .foil-image {
        margin-top: 0px;
    }
}

.msc-ss-carousel-strip {
    display: flex;
    justify-content: center;
}

.ob-summary-container > .ob-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 30px;
    margin: auto auto;
}

.ms-buybox__quantity {
    padding: 0 5px;

    input {
        width: 44px;
        height: 44px;
        text-align: center;
    }
}

.ms-buybox__add-to-cart-container {
    flex-grow: 3;
    padding: 0 5px;

    button {
        width: 100%;
    }
}

.ms-buybox__add-to-wishlist-container {
    padding: 0 5px;

    button {
        width: 44px;
        height: 44px;
    }
}

.ms-buybox__quantity,
.ms-buybox__add-to-cart-container,
.ms-buybox__add-to-wishlist-container {
    display: flex;
    justify-content: center;
}

.ms-buybox__product-quantity-label {
    display: none;
}

@media (min-width: 992px) {
    .ms-media-gallery .ms-media-gallery__thumbnail-item {
        height: 150px;
    }
}

/*test */

@media (min-width: $msv-breakpoint-m) {
    .ms-media-gallery {
        .msc-carousel__item {
            max-height: 850px;
        }
    }
}

.liquid-wakeboard-spacer {
    height: 75px;
}

.product-video-container {
    padding: 30px 0;
    .product-video {
    }
}

.feature-focus-section {
    margin: 30px 0;
    @media only screen and (max-width: 768px) {
        margin: 0 30px;
    }
}
.feature-focus-image,
.feature-focus-text {
    padding: 0 20px;
    @media only screen and (max-width: 768px) {
        padding: 20px 0;
    }
}

.feature-focus-reversed > div {
    display: flex;
    flex-direction: row-reverse;
}

.ms-tab-tabs-section {
    justify-content: center;
}

.ms-buybox__media-gallery {
    padding-bottom: 100px;
}

.product-page-main-container #subfooter {
    background-color: #f9f9f9;
}

.ms-buybox__product-description {
    font-family: 'Futura PT Book', monospace;
    font-weight: 400;
    div {
        ul {
            li {
                font-size: 16px;
            }
        }
    }
}

.retail-button {
    background-color: #000;
    border: none;
    color: white;
    padding: 13px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
}

.ob-summary-container > .ob-summary {
    padding-bottom: 0px;
}

.retail-button a {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    :hover {
        text-decoration: none;
    }
}

.retail-button a:hover {
    text-decoration: none;
}

.dynamic-images-container {
    padding-top: 30px;
}
.product-features-lists {
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    flex-wrap: nowrap;
    ul {
        width: 50%;
        list-style: none;
        text-align: center;
        @media (max-width: 768px) {
            width: 100%;
        }
        li {
            padding-bottom: 20px;
            h5 {
                font-family: 'Futura PT Book', monospace !important;
                font-size: 18px;
            }
        }
    }
}

//REMOVE TO SHOW ELFSITE POPUP DESCS AGAIN
.fslightbox-caption-wrapper {
    display: none;
}

.fslightbox-caption-title,
.fslightbox-caption-description {
    font-weight: bold;
}

.fslightbox-caption-body {
    padding: 15px 30px;
    background-color: white;
    margin: 0 auto;
    max-width: 300px;
}
.ms-tab-header-section {
    display: none;
}

.foil-tabbed-container {
    padding-bottom: 30px;
}

.related-products {
    .msc-ss-carousel-strip {
        justify-content: flex-start;
    }

    .ms-product-collection__item {
        width: 228px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .msc-product .msc_image {
        max-width: 100%;
        height: auto;
    }

    .msc-product__title {
        font-family: 'Futura Bold', Arial, Tahoma, sans-serif !important;
    }

    .msc-price__actual {
        font-weight: normal;
    }
}

ul.foil-construction-list {
    list-style: circle;
    li {
        padding: 5px 0;
    }
}

.product-specs {
    text-align: center;
}

.ms-wishlist-items__product-status {
    display: none;
}

// Vest Spec Table Display
.vests-tables {
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    .table-responsive {
        th {
            text-align: center;
        }
        width: 100%;
        max-width: 100%;
        padding: 0 10px;
        @media (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            padding: 0;
        }
    }
}

// Attatch .linked-image class to an obrien content module image and use an action link (with text) to allow the image to be clickable
.linked-image {
    display: grid;
    grid-template: 1fr / 1fr;
    place-items: center;
    > * {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
    }
    > .obrien-content-block-simple__image {
        //placeholder for image manipulation
    }
    > .obrien-content-block-simple__details {
        height: 100%;
        width: 100%;
        * {
            height: 100%;
            width: 100%;
            color: transparent;
        }
    }
}

.linked-image.desktop-image {
    @media (max-width: 768px) {
        display: none;
    }
}

.linked-image.mobile-image {
    display: none;
    @media (max-width: 768px) {
        display: grid;
    }
}

/* FOIL PRODUCT PAGES */
.foil-product-page {
    .foil-image-container {
        padding-bottom: 30px;
        .ffb-board-construction-list {
            column-gap: 50px;
            padding: 0 15px;
            list-style: decimal;
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            font-size: 16px;
            letter-spacing: 0.025em;
            line-height: 1.6;
            font-family: 'Futura PT Book', Arial, Tahoma, sans-serif;
            font-weight: 400;
            @media (max-width: 768px) {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
            }
        }
    }
}

.foil-product-page-banner-container {
    padding-top: 20px;
}
