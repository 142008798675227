.f3-title-container {
    margin-bottom: 50px;
    .f3-title {
        text-align: center;
        text-transform: uppercase;
    }
}

.f3-main-container {
    > .row {
        > div:first-of-type {
            flex: 0 0 25%;
            max-width: 25%;
            @media (max-width: 768px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        > div:last-of-type {
            flex: 0 0 75%;
            max-width: 75%;
            @media (max-width: 768px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    .f3-left-container {
        .f3-image-block {
            padding-right: 10px;
        }
    }
    .f3-right-container {
        .f3-desc {
            .f3-desc-title {
                font-family: 'Futura Bold', Arial, Tahoma, sans-serif !important;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 26px;
                @media (max-width: 768px) {
                    text-align: center;
                }
            }
            .f3-desc-text {
                margin: 30px 0;
                @media (max-width: 768px) {
                    text-align: center;
                }
            }
            .f3-desc-year {
                font-weight: bold;
                font-size: 20px;
                text-transform: uppercase;
                background-color: #e72129;
                color: white;
                text-align: center;
            }
        }
        div.fff-button-container {
            display: flex;
            flex-direction: row;
            justify-content: left;
            > div {
                width: 16%;
                @media (max-width: 768px) {
                    width: 100%;
                }
                a.fff-button {
                    padding: 6px 10px;
                    text-decoration: none;
                    color: white;
                    background: #161616;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }
        }
    }
}
.f3-main-container:last-of-type {
    margin-bottom: 50px;
}
