.online-retailer-container {
    padding: 30px 0;
    .row {
        display: flex;
        flex-direction: row;
        > div {
            /* width: 25%;
            max-width: 25%; */
            @media (max-width: 768px) {
                width: 50%;
                max-width: 50%;
            }
            .online-retailer-block {
                padding: 10px;
                .ms-content-block__details {
                    .ms-content-block__text {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.retailer-inquiry-container {
    padding-bottom: 30px;
}

.premiere-retailers-heading {
    @media (max-width: 768px) {
        font-size: 32px;
    }
}
