// overrides
.ms-header .ms-header__topbar.liquid {
    height: auto;
    justify-content: space-between;
}

.ms-header .ms-search__form-control:focus,
.ms-header .ms-search__form-cancelSearch:focus {
    outline-offset: 0px;
    border: none;
}

.ms-header .ms-search__icon:focus {
    outline-offset: 0px !important;
    border: none;
}

.ms-header .ms-header__account-info .ms-header__signin-button:focus {
    outline-offset: 0px !important;
    border: none;
}

// I do not know why... I'm confused as well as to why this is display: none; It makes no sense.

.ms-header .ms-header__account-info {
    display: block;
}

.ms-header .ms-header__account-info a {
    padding: 0;
}

.ms-header__logo {
    max-width: 300px;
}

.ms-header .ms-header__topbar {
    justify-content: space-around;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    font-family: $futura-bold, Arial, Tahoma, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
}

.ms-header .ms-header__nav-icon {
    color: #161616;
}

:root {
    --msv-header-bg: #ffffff;
    --msv-header-font-color: #161616;
    --msv-header-border: #ffffff;
    --msv-nav-bg: #121212;
    --msv-nav-font-color: white;
    --msv-promo-banner-bg: #d2d2d2;
    --msv-promo-banner-border: #d2d2d2;
    --msv-promo-banner-font-color: #121212;
    --msv-nav-carot-color: #ffffff;
    --msv-nav-font-family: $futura-bold, Arial, Tahoma, sans-serif;
    --msv-promo-banner-bg: #efefef;
    --msv-promo-banner-border: #efefef;
    --msv-promo-banner-font-color: #161616;
}

.ms-search__form-submitSearch {
    background-color: var(--msv-promo-banner-bg) !important;
}

@media only screen and (max-width: 1260px) {
    .ms-header__topbar > .freedom-test {
        display: flex;
        flex-direction: row-reverse;
    }
    .ms-header__mobile-hamburger-menu-links .ms-nav__list__item__button,
    .ms-header__mobile-hamburger-menu-links .ms-nav__list__item__link {
        background-color: transparent !important;
        color: var(--msv-header-font-color);
    }
}

.ms-promo-banner .ms-promo-banner__text,
.ms-promo-banner .ms-promo-banner__link {
    font-weight: var(--msv-font-weight-bold);
}

.ms-promo-banner .ms-promo-banner__close-button {
    position: absolute;
    right: 0;
}

.ms-promo-banner .ms-promo-banner__link {
    text-decoration: none;
}

.ms-header__mobile-hamburger-menu-links .ms-nav__list__item__button,
.ms-header__mobile-hamburger-menu-links .ms-nav__list__item__link {
    border-bottom: none !important;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:focus,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus,
.ms-header__container .ms-header__logo a:focus,
.ms-header__container .ms-header__logo,
.ms-nav__list__item__button:focus,
.ms-nav__list__item__link:focus {
    border: none;
    outline: none;
    outline-offset: 0px !important;
}

@media screen and (min-width: 992px) {
    .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button::after {
        padding-bottom: 3px;
    }
}

@media screen and (min-width: 992px) {
    .ms-nav > .ms-nav__list {
        justify-content: center;
    }
    .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:focus,
    .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus {
        border: none;
        outline: none;
    }
}

.ms-cookie-compliance {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
}

.ms-cookie-compliance__accept-button {
    background-color: #e72129;
    border: #e72129;
}

.ms-cookie-compliance__accept-button:hover,
.ms-cookie-compliance__accept-button:focus {
    background-color: black;
    border-color: black;
}

.ms-header .ms-header__preferred-store-btn,
.ms-header .ms-search__icon,
.ms-header .ms-header__signin-button,
.ms-header .msc-wishlist-icon,
.ms-header .msc-cart-icon,
.ms-header .ms-header__profile-button {
    font-family: 'Futura PT Book', monospace;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:focus,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus,
.ms-nav__list__item__button:focus,
.ms-nav__list__item__link:focus,
.ms-header__container .ms-header__logo a:focus,
.ms-search__form-control:focus {
    border: none;
    outline: none;
}

.navBar-mobile {
    a,
    button,
    span {
        color: #a7a9aa !important;
    }
    a:hover,
    a:focus,
    button:hover,
    button:focus,
    span:hover,
    span:focus {
        color: #e72129 !important;
    }
    background-color: #161616 !important;

    li {
        > button.accordion-inner {
            span {
                font-size: 18px;
            }
        }
        div.accordion,
        ul {
            span,
            a {
                font-size: 16px;
            }
            a {
                padding: 5px 20px;
            }
        }
    }
}

.ms-header__signout-button-text,
.ms-signin-info__account-link-button > div {
    padding: 10px 15px;
    color: #a7a9aa !important;
    font-weight: normal;
}

@media (max-width: 992px) {
    .navBar-mobile {
        padding-top: 30px;
        li {
            padding: 5px 0;
        }
    }

    .ms-header__mobile-hamburger-menu-links,
    .account-mobile > a {
        background-color: #161616 !important;
        color: #a7a9aa !important;
        span,
        .ms-search__icon::before {
            color: #a7a9aa;
        }
    }
    .ms-header .msc-wishlist-icon:before {
        color: #a7a9aa;
    }
    .ms-header .ms-header__collapsible-hamburger .ms-search .ms-search__label .ms-search__icon,
    .ms-header .ms-header__collapsible-hamburger .ms-header__wishlist-mobile,
    .ms-header .ms-header__account-info.account-mobile .msc-btn {
        padding-left: 20px;
    }
}

//Red Styling for last menu item (clearence)
.submenu {
    .product-nav-container__list:last-of-type {
        h4.freedom-nav__heading__title {
            color: red !important;
        }
    }
}
