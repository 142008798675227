// Pages index file

@import '2022-special-product-page';
@import 'accessibility';
@import 'addresses.scss';
@import 'cart';
@import 'checkout';
@import 'closeouts';
@import 'collection_page';
@import 'custom-boards';
@import 'f3.scss';
@import 'faq';
@import 'home';
@import 'order-details.scss';
@import 'orders.scss';
@import 'privacy-policy';
@import 'product_page';
@import 'profile.scss';
@import 'qr-pages.scss';
@import 'refund-policy';
@import 'reservations';
@import 'retailers';
@import 'shipping-policy';
@import 'story';
@import 'team';
@import 'terms-of-service';
@import 'video';
@import 'warranty';
@import 'wishlist.scss';
