.board-finder-accordion-wrapper {
    background-color: transparent;
    margin-top: -64px;
}
.foilboard-page-header-image-container {
    border-bottom: solid 64px #efefef;
}

.board-finder-accordion-container {
    .board-finder-accordion {
        .ms-accordion-header-section {
            display: none;
        }
        .board-finder-accordion-item {
            .drawer.ms-accordion-item__drawer {
                border: none;
                .drawer__button {
                    justify-content: center;
                    font-weight: bold;
                    // font-family: 'Konexy', 'Futura PT Book', Helvetica, Arial, sans-serif !important;
                    font-family: 'Futura PT Book', Helvetica, Arial, sans-serif !important;
                    font-size: 20px;
                    letter-spacing: 2px;
                    span {
                        padding-right: 10px;
                    }
                }
            }

            .board-finder-fragment {
                .row {
                    padding-bottom: 20px;
                }
                .series:last-child {
                    padding-bottom: 30px;
                }
                .series {
                    .header {
                        > .row > .col-12.col-md:first-child {
                            max-width: 33%;
                            @media only screen and (max-width: 768px) {
                                max-width: 100%;
                            }
                        }
                        .row {
                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                        .heading-image {
                            img {
                                margin: auto;
                            }
                        }
                        .heading-text {
                            @media only screen and (max-width: 768px) {
                                padding-bottom: 30px;
                            }
                        }
                    }
                    .boards {
                        .row {
                            justify-content: center;
                        }
                        > .row > div {
                            max-width: 25%;
                            @media only screen and (max-width: 768px) {
                                min-width: 50%;
                                padding-bottom: 20px;
                            }
                            @media only screen and (max-width: 400px) {
                                min-width: 100%;
                                padding-bottom: 20px;
                            }
                        }
                        .board {
                            .ms-content-block__details {
                                text-align: center;
                                font-size: 20px;
                                font-weight: bold;
                                padding-top: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ffb-board-finder-iframe > iframe {
    border: 0;

    @media only screen and (max-width: 540px) {
        height: 1050px;
    }
    @media only screen and (min-width: 540px) {
        height: 600px;
    }
}
